import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, Observable, of } from 'rxjs';
import { JSend } from '../../jsend.model';
import { plainToClass } from 'class-transformer';
import { SaveSelfieRequest } from '../models/request/save-selfie-request.model';
import { SaveDocumentRequest } from '../models/request/save-document-request.model';
import { environment } from 'src/environments/environment';
import { IdentityDocumentType } from '../models/id-document-type.model';


@Injectable({
  providedIn: 'root'
})
export class S3Service {

  private apiBase = environment.api.base; // Api
  private documentAuthentication = this.apiBase + '/document-authentication/:authorization_uuid/:authorization_user_uuid/generate-url'

  constructor(
    private http: HttpClient,
  ) { }

  public getUploadUrl(authorizationUuid: string, userUuid: string, idDocumentType: IdentityDocumentType = 'DNI'): Observable<UrlInformation> {
    let url = this.documentAuthentication
      .replace(':authorization_uuid', authorizationUuid)
      .replace(':authorization_user_uuid', userUuid);

    let stream = this.http.post<JSend<{ url_information: UrlInformation }>>(url, { "has_double_side": idDocumentType === 'DNI' });
    return stream.pipe(map((response) => {
      return plainToClass(UrlInformation, response.data.url_information);
    }));

  }

  public upload(url: string, image: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/octet-stream');

    let stream = this.http.put(url, image, { headers });
    return stream;
  }

}


export class UrlInformation {
  constructor(data: Partial<UrlInformation> = {}) {
    Object.assign(this, data);
  }

  front_url: string;
  back_url: string;
}
