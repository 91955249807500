import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorResponse } from 'src/app/external/models/process-status.model';
import { RouteBuilder } from 'src/app/route-builder';

@Component({
  selector: 'app-page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.scss']
})
export class PageErrorComponent implements OnInit {

  @Input()
  public message?: string;

  public routeBuilder: RouteBuilder;

  public errorInfo: any

  constructor(private route: ActivatedRoute) {
    this.routeBuilder = new RouteBuilder(route);
  }
  ngOnInit(): void {
    const errorCode = this.routeBuilder.getErrorCode();
    const errorResponse: ErrorResponse = new ErrorResponse();
    this.errorInfo = errorResponse.getStatusByCode(errorCode);
  }

  public redirectTo() {

  }

}
