<div class="card centered">
    <div class="row h-100">
        <div class="card-body" style="min-height: 550px">
            <h4 *ngIf="!modeSelected" class="ag-title">
                Seleccione el modo de subida.
            </h4>
            <spinner *ngIf="processing"></spinner>

            <!-- <span
                *ngIf="!modeSelected"
                style="display: flex; flex-direction: column"
            >
                <button
                    type="button"
                    class="btn agree-btn btn-block mode-btn"
                    (click)="startManualMode()"
                >
                    <i class="mode-icon bi bi-upload"></i>

                    Subida Manual
                </button>

                <button
                    type="button"
                    class="btn agree-btn btn-block mode-btn"
                    (click)="startCaptureMode()"
                >
                    <i class="mode-icon bi bi-camera"></i>
                    Tomar Fotografía
                </button>
            </span> -->

            <facephi-selphid
                *ngIf="!processing && modeSelected"
                containerCssClass="bg-white"
                bundlePath="../../../assets/selphid"
                language="es"
                [attr.style]="
                    'width: ' +
                    this.settings.widgetContainerWidth +
                    '; height: ' +
                    this.settings.widgetContainerHeight +
                    ';'
                "
                [attr.documentAspectRatio]="85.6 / 53.98"
                [attr.captureMode]="this.settings.widgetCaptureMode"
                [attr.previewCapture]="this.settings.widgetPreviewCapture"
                [attr.forceLandscape]="this.settings.widgetForceLandscape"
                [attr.initialTip]="this.settings.widgetInitialTip"
                [attr.cameraWidth]="this.settings.widgetCameraWidth"
                [attr.cameraHeight]="this.settings.widgetCameraHeight"
                [attr.captureSensibility]="
                    this.settings.widgetCaptureSensibility
                "
                [attr.videoRecord]="this.settings.widgetVideoRecord"
                [attr.videoRecordType]="
                    this.settings.fPhiSelphIdConstants.RecorderType.Remote
                "
                [attr.videoRecordScale]="
                    this.settings.widgetCameraWidth < 1280 ? 1 : 0.5
                "
                [attr.showLog]="this.settings.widgetShowLog"
                [attr.debugMode]="this.settings.widgetDebugMode"
                [attr.startSimpleMode]="this.settings.widgetStartSimpleMode"
                (onModuleLoaded)="onModuleLoaded($event)"
                (onExtractionFinished)="onExtractionFinished($event)"
                (onUserCancelled)="onUserCancelled()"
                (onExceptionCaptured)="onExceptionCaptured($event)"
                (onExtractionTimeout)="onExtractionTimeout($event)"
                (onTrackStatus)="onTrackStatus($event)"
            >
            </facephi-selphid>
        </div>
    </div>
</div>
