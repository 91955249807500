<div class="container">
    <div class="row h-100">
        <ag-card>
            <h3 cardHeader class="ag-title">¡Bienvenido {{ fullName }}! 😊</h3>

            <ng-container cardBody>
                <span class="d-grid gap-4">
                    <ng-container>
                        <ag-conditions
                            [companyName]="companyName"
                        ></ag-conditions>

                        <div class="row">
                            <div class="col-md-6 offset-md-3">
                                <div class="card">
                                    <div class="card-body p-4">
                                        <h5 class="card-title">
                                            Ingresando tu clave CIEC, está
                                            aceptando:
                                        </h5>
                                        <div class="card-text m-3">
                                            <i
                                                class="bi bi-check-circle-fill text-success"
                                            ></i>
                                            Tratamiento de datos personales,
                                            Términos y condiciones y
                                            Autorización de Información
                                            Crediticia.
                                        </div>
                                        <div class="card-text m-3">
                                            <i
                                                class="bi bi-check-circle-fill text-success"
                                            ></i>
                                            Cláusula para utilizar medios
                                            electrónicos.<br />
                                            <small class="text-muted"
                                                >Las partes acuerdan que el
                                                Cliente podrá expresar su
                                                consentimiento respecto a la
                                                presente cláusula, así como
                                                tener acceso a los servicios
                                                ofrecidos por
                                                <strong
                                                    >Agree Mex S.A. de
                                                    C.V.</strong
                                                >
                                                mediante medios electrónicos
                                                tales como
                                                <strong>Firma CIEC</strong
                                                >.</small
                                            >
                                        </div>

                                        <div>
                                            <span class="input-lg">
                                                <label
                                                    for="nameInput"
                                                    class="form-label"
                                                    >RAZÓN SOCIAL</label
                                                >
                                                <input
                                                    type="text"
                                                    [(ngModel)]="companyName"
                                                    [disabled]="true"
                                                    placeholder="RAZÓN SOCIAL"
                                                    class="form-control form-control-lg"
                                                    id="nameInput"
                                                    aria-label="RFC"
                                                    aria-describedby="RFC"
                                                />
                                            </span>
                                            <span class="input-lg">
                                                <label
                                                    for="rfcInput"
                                                    class="form-label"
                                                    >RFC</label
                                                >
                                                <input
                                                    type="text"
                                                    [(ngModel)]="rfc"
                                                    [disabled]="true"
                                                    placeholder="RFC"
                                                    class="form-control form-control-lg"
                                                    id="rfcInput"
                                                    aria-label="RFC"
                                                    aria-describedby="RFC"
                                                />
                                            </span>
                                            <span
                                                *ngIf="!successMessage"
                                                class="input-lg"
                                            >
                                                <label
                                                    for="ciecInput"
                                                    class="form-label"
                                                    >CLAVE CIEC</label
                                                >
                                                <div class="input-group">
                                                    <input
                                                        id="ciecInput"
                                                        [type]="
                                                            showPassword
                                                                ? 'text'
                                                                : 'password'
                                                        "
                                                        [(ngModel)]="
                                                            ciecPassword
                                                        "
                                                        [disabled]="processing"
                                                        placeholder="Clave CIEC..."
                                                        class="form-control form-control-lg"
                                                        aria-label="Clave Ciec"
                                                        aria-describedby="Clave Ciec"
                                                        minlength="8"
                                                        (keyup.enter)="
                                                            ciecPassword?.length >=
                                                                8 &&
                                                                submit()
                                                        "
                                                    />
                                                    <a
                                                        class="btn"
                                                        type="button"
                                                    >
                                                        <i
                                                            (click)="
                                                                showPassword =
                                                                    !showPassword
                                                            "
                                                            sty
                                                            [class]="
                                                                showPassword
                                                                    ? 'bi bi-eye-slash-fill'
                                                                    : 'bi bi-eye-fill'
                                                            "
                                                        ></i>
                                                    </a>
                                                </div>

                                                <!-- <a
                                                    (click)="
                                                        showPassword =
                                                            !showPassword
                                                    "
                                                >
                                                    <i
                                                        [class]="
                                                            showPassword
                                                                ? 'bi bi-eye-slash-fill'
                                                                : 'bi bi-eye-fill'
                                                        "
                                                    ></i>
                                                </a> -->
                                            </span>
                                        </div>

                                        <div class="d-grid">
                                            <div
                                                *ngIf="successMessage"
                                                class="alert alert-success d-flex align-items-center"
                                            >
                                                <i
                                                    class="bi bi-check-circle-fill"
                                                ></i>

                                                {{ successMessage }}
                                            </div>
                                            <div
                                                *ngIf="errorMessage"
                                                class="alert alert-danger d-flex align-items-center"
                                            >
                                                <i
                                                    class="bi bi-exclamation-circle-fill"
                                                ></i>

                                                {{ errorMessage }}
                                            </div>

                                            <button
                                                type="button"
                                                class="btn agree-btn"
                                                (click)="submit()"
                                                *ngIf="!successMessage"
                                                [disabled]="
                                                    ciecPassword?.length < 8 ||
                                                    processing
                                                "
                                            >
                                                <span
                                                    *ngIf="
                                                        !processing;
                                                        else sending
                                                    "
                                                >
                                                    <i
                                                        class="icon bi bi-send"
                                                    ></i>
                                                    Enviar
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </span>
            </ng-container>
            <!-- <ng-container cardFooter>
      </ng-container> -->
        </ag-card>
    </div>
</div>

<ng-template #sending>
    <div
        class="spinner-border spinner-border-sm text-light"
        role="status"
    ></div>
    Enviando
</ng-template>
