<div class="spinner-main" [class.block-ui]="blockUI">
    <div class="ag-spinner" [class.zoom]="!skipZoomIn">
        <div class="ag-center"></div>
        <div class="ag-01 ag-child"></div>
        <div class="ag-02 ag-child"></div>
        <div class="ag-03 ag-child"></div>
        <div class="ag-04 ag-child"></div>
        <div class="ag-05 ag-child"></div>
        <div class="ag-06 ag-child"></div>

        <div class="ag-01 ag-semi"></div>
        <div class="ag-02 ag-semi"></div>
        <div class="ag-03 ag-semi"></div>
        <div class="ag-04 ag-semi"></div>
        <div class="ag-05 ag-semi"></div>
        <div class="ag-06 ag-semi"></div>
    </div>
    <div *ngIf="message" class="legend">{{ message }}</div>
</div>
