import { Component, Input, OnInit } from '@angular/core';
import { StepperManager } from '../../stepper-manager';

@Component({
  selector: 'card-success-process',
  templateUrl: './card-success-process.component.html',
  styleUrls: ['./card-success-process.component.scss']
})
export class CardSuccessProcessComponent extends StepperManager implements OnInit {
  @Input()
  text?: string;

  @Input()
  title?: string;

  @Input()
  retryStep: number;

  @Input()
  linkToContinue: any = ['/']

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
