import { Type } from "class-transformer";

export class ProcessStatus {
  constructor(data: Partial<ProcessStatus> = {}) {
    Object.assign(this, data);
  }
  readonly id: string;
  uuid: string;
  name: string;
  surname: string;
  email: string;

  @Type(() => FiscalIdentification)
  fiscal_information: FiscalIdentification;

  identity_number: string;

  @Type(() => ValidationStatus)
  validated: ValidationStatus;

  document_status: {
    tag: "FRONT_PENDING" | "BACK_PENDING" | "FRONT_BACK_PENDING"
  }

  language: "es" | "en" | "pt";

  @Type(() => Date)
  created_at: Date;

  @Type(() => Date)
  updated_at?: Date;

  message?: string;

  user_status?: any;

  get is_pending_manual() {
    return this.user_status?.name === 'PENDING_MANUAL'
  }

  get document_validated() {
    return this.validated?.document;
  }
  get selfie_validated() {
    return this.validated?.selfie;
  }
}

export class FiscalIdentification {
  constructor(data: Partial<FiscalIdentification> = {}) {
    Object.assign(this, data);
  }

  value: string;
  type: number;
}

export class ValidationStatus {
  constructor(data: Partial<FiscalIdentification> = {}) {
    Object.assign(this, data);
  }
  /**
   * Document or selfie are validated
   */
  document: boolean;
  selfie: boolean;
}


/**
 * The Response Message of a [[ErrorResponse]]
 *
 * ## Sample values
 * | agree_code | agree_status          | description                                                               |
 * |:----------:|:---------------------:|:-------------------------------------------------------------------------:|
 * |4004        |CLIENT_NOT_FOUND       | De acuerdo al UUID de cliente y autorizacion no se encuentra informacion  |
 * |4005        |SELFIE_NOT_FOUND       | Al momento de borrar una selfie, la misma no existe en base de datos      |
 * |4006        |DOCUMENT_NOT_FOUND     | Al momento de borrar un documento, el mismo no existe en la base de datos |
 * |4007        |INVALID_SELFIE         | Se subió una selfie invalida (facephi la detecto como invalida)           |
 * |4008        |INVALID_DOCUMENT       | Se subió un documento invalido  (facephi no leyo bien sus campos)         |
 * |4009        |AUTH_FAILED            | No se pudo matchear entre documento y selfie                              |
 * |4010        |AUTH_NOT_FOUND         | No se encontro la autorizacion provista                                   |
 * |4011        |NOT_MATCHING_DOCUMENT  | El documento no pertenece al cliente proporcionado                        |
 * |4012        |                       |                                                                           |
 * |4013        |                       |                                                                           |
 * |4014        |RESTART_PROCESS        | Fallo la autenticación, no pudo validar el documento y la selfie.         |
 * |4015        |MANUAL_VALIDATION      | La solicitud va a ser validada manualmente                                |
 * |4016        |DOCUMENT_STATUS_TIMEOUT| El estado del documento esta tardando en actualizarse                     |
 * |500         |INTERNAL_SERVER_ERROR  | Fallo el servicio.                                                        |
 */
export class ErrorResponse {
  constructor(data: Partial<ErrorResponse> = {}) {
    Object.assign(this, data);
  }

  public agree_code: string;
  public agree_status: number;
  public message: string;
  public status: string;

  get message_translated() {
    return this.getStatusByCode(this.agree_code).message;
  }

  get icon() {
    return this.getStatusByCode(this.agree_code).icon;
  }

  public getStatusByCode(agree_code: string) {
    switch (agree_code) {
      case "CLIENT_NOT_FOUND":
        return {
          message: "¡Cliente inexistente!",
          agree_code: "CLIENT_NOT_FOUND",
          agree_status: 4004,
          icon: "bi-people-fill",
          redirectTo: null
        }
      case "SELFIE_NOT_FOUND":
        return {
          message: "¡La selfie no existe!",
          agree_code: "SELFIE_NOT_FOUND",
          agree_status: 4005,
          icon: "bi-person-bounding-box",
          redirectTo: "face-authentication"
        }
      case "DOCUMENT_NOT_FOUND":
        return {
          message: "¡Documento no encontrado!",
          agree_code: "DOCUMENT_NOT_FOUND",
          agree_status: 4006,
          icon: "bi-credit-card-2-front",
          redirectTo: "document-authentication"
        }
      case "INVALID_SELFIE":
        return {
          message: "¡Hubo un error validar el rostro, verifique estar en un lugar con buena luz!",
          agree_code: "INVALID_SELFIE",
          agree_status: 4007,
          icon: "bi-person-bounding-box",
          redirectTo: "face-authentication"
        }
      case "INVALID_DOCUMENT":
        return {
          message: "¡Hubo un error al leer el documento, verifique que se vean bien todos los datos!",
          agree_code: "INVALID_DOCUMENT",
          agree_status: 4008,
          icon: "bi-credit-card-2-front",
          redirectTo: "document-authentication"
        }
      case "AUTH_FAILED":
        return {
          message: "¡No se pudo validar entre documento y la selfie!",
          agree_code: "AUTH_FAILED",
          agree_status: 4009,
          icon: "bi-shield-exclamation",
          redirectTo: "user-status"
        }
      case "AUTH_NOT_FOUND":
        return {
          message: "¡No se encontro la autorizacion provista!",
          agree_code: "AUTH_NOT_FOUND",
          agree_status: 4010,
          icon: "bi-shield-exclamation",
          redirectTo: "user-status"
        }
      case "NOT_MATCHING_DOCUMENT":
        return {
          message: "El documento no pertenece al cliente proporcionado.",
          agree_code: "NOT_MATCHING_DOCUMENT",
          agree_status: 4011,
          icon: "bi-credit-card-2-front",
          redirectTo: "user-status"
        }
      case "RESTART_PROCESS":
        return {
          message: "Fallo la autenticación, vuelva a reintentar el proceso de validacion.",
          agree_code: "RESTART_PROCESS",
          agree_status: 4014,
          icon: "bi-credit-card-2-front",
          redirectTo: "user-status"
        }
      case "MANUAL_VALIDATION":
        return {
          message: "¡La solicitud va a ser validada manualmente y será notificado/a cuando esté completa!",
          agree_code: "MANUAL_VALIDATION",
          agree_status: 4015,
          icon: "bi-patch-check-fill",
          redirectTo: "user-status"
        }
      case "DOCUMENT_STATUS_TIMEOUT":
        return {
          message: "El estado del documento esta tardando mucho tiempo en actualizarse, intente de nuevo mas tarde.",
          agree_code: "DOCUMENT_STATUS_TIMEOUT",
          agree_status: 4016,
          icon: "bi-shield-exclamation",
          redirectTo: "user-status"
        }
      case "INTERNAL_SERVER_ERROR":
        return {
          message: "Ha ocurrido un error interno en el servicio de validación, por favor intente más tarde.",
          agree_code: "INTERNAL_SERVER_ERROR",
          agree_status: 500,
          icon: "bi-shield-exclamation",
          redirectTo: "user-status"
        }
      default:
        return {
          message: "Hubo un error!",
          agree_code: "DEFAULT_ERROR",
          agree_status: 1,
          icon: "bi-x-circle-fill",
          redirectTo: "user-status"
        }
    }
  }
}


