<div
    *ngIf="isWidgetCaptureStarted"
    class="col-12 h-100"
    style="min-height: 550px"
>
    <ag-card class="h-100">
        <ng-container cardBody>
            <spinner *ngIf="processing"></spinner>
            <facephi-selphi
                *ngIf="!processing"
                class="h-100"
                bundlePath="../../../assets/selphi"
                language="es"
                [attr.livenessMode]="widgetLivenessMode"
                [attr.cameraWidth]="widgetCameraWidth"
                [attr.cameraHeight]="widgetCameraHeight"
                [attr.cameraType]="widgetCameraType"
                [attr.faceTracking]="widgetFaceTracking"
                [attr.interactible]="widgetInteractible"
                [attr.tutorial]="widgetTutorial"
                [attr.stabilizationStage]="widgetStabilizationStage"
                [attr.logImages]="true"
                [attr.cropFactor]="1.7"
                [attr.videoRecord]="widgetVideoRecord"
                [attr.videoRecordType]="FPhiSelphiConstants.RecorderType.Remote"
                [attr.videoRecordScale]="widgetCameraWidth < 1280 ? 1 : 0.5"
                [attr.showLog]="widgetShowLog"
                [attr.debugMode]="widgetDebugMode"
                (onModuleLoaded)="onModuleLoaded($event)"
                (onStabilizing)="onStabilizing($event)"
                (onExtractionFinish)="onExtractionFinish($event)"
                (onUserCancel)="onUserCancel()"
                (onExceptionCaptured)="onExceptionCaptured($event)"
                (onLivenessError)="onLivenessError($event)"
                (onLivenessErrorButtonClick)="onLivenessErrorButtonClick()"
                (onExtractionTimeout)="onExtractionTimeout($event)"
                (onTimeoutErrorButtonClick)="onTimeoutErrorButtonClick()"
                (onTrackStatus)="onTrackStatus($event)"
            >
            </facephi-selphi>
        </ng-container>
    </ag-card>
</div>
