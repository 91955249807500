import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteBuilder } from 'src/app/route-builder';
import { FacephiService } from '../../services/facephi.service';
import { ProcessStatus } from '../../models/process-status.model';

@Component({
  selector: 'face-validation-process',
  templateUrl: './face-validation-process.component.html',
  styleUrls: ['./face-validation-process.component.scss']
})
export class FaceValidationProcessComponent implements OnInit {

  public step: number = 1
  public processStatus: ProcessStatus;
  public routeBuilder: RouteBuilder;
  public processing: boolean = false;

  constructor(private facephiService: FacephiService, private route: ActivatedRoute, public router: Router) {
    this.routeBuilder = new RouteBuilder(route);
  }

  ngOnInit(): void {
    this.checkStatus();
  }

  private checkStatus() {
    const processId = this.routeBuilder.getValidationProcessId();
    const clientId = this.routeBuilder.getClientId()
    this.processing = true;
    this.facephiService.getStatus(processId, clientId).subscribe(status => {
      this.processStatus = status;
      if (this.processStatus.selfie_validated) {
        // Already verified selfie
        this.router.navigate([this.routeBuilder.getStatusRoute()]);
      }
      this.processing = false;
    })
  }

  public setStep(event) {
    this.step = event
  }
}
