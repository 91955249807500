<div class="container">
  <div class="row h-100">
    <div class="col-12 h-100" style="min-height: 550px">
      <ag-card class="h-100">
        <ng-container cardBody>
          <span class="display-flex">
            <i class="icon bi bi-exclamation-triangle"></i>

            <p class="text-description">
              Disculpe, esta URL no es válida.
            </p>
          </span>
        </ng-container>
      </ag-card>
    </div>
  </div>
</div>