<div
    class="box"
    style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    "
>
    <div class="circle"></div>

    <span
        class="reveal-text"
        style="word-break: break-all; display: flex; flex-direction: column"
    >
        <i class="icon bi bi-x-circle-fill"></i>
        <h2>{{ text }}</h2>
    </span>

    <span class="logoPosition">
        <logo-agree></logo-agree>
    </span>

    <button
        type="button"
        class="btn white-btn btn-block"
        (click)="setStep(retryStep)"
    >
        Reintentar
    </button>
</div>
