import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import "@facephi/selphi-widget-web";
import "@facephi/selphid-widget-web";
import { UiModule } from '../ui/ui.module';
import { ValidationStatusComponent } from './components/validation-status/validation-status.component';
import { CardSuccessProcessComponent } from './components/card-success-process/card-success-process.component';
import { AppRoutingModule } from '../app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AgreeSelphIdComponent } from './components/agree-selph-id/agree-selph-id.component';
import { AgreeSelphiComponent } from './components/agree-selphi/agree-selphi.component';
import { FaceValidationTutorialComponent } from './components/face-validation-tutorial/face-validation-tutorial.component';
import { DniValidationTutorialComponent } from './components/dni-validation-tutorial/dni-validation-tutorial.component';
import { CardErrorProcessComponent } from './components/card-error-process/card-error-process.component';
import { FaceValidationProcessComponent } from './components/face-validation-process/face-validation-process.component';
import { DocumentValidationProcessComponent } from './components/document-validation-process/document-validation-process.component';
import { CiecStatusComponent } from './components/ciec-status/ciec-status.component';

@NgModule({
  declarations: [
    AgreeSelphIdComponent,
    AgreeSelphiComponent,
    FaceValidationTutorialComponent,
    DniValidationTutorialComponent,
    ValidationStatusComponent,
    CardSuccessProcessComponent,
    CardErrorProcessComponent,
    FaceValidationProcessComponent,
    DocumentValidationProcessComponent,
    CiecStatusComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    UiModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [
    AgreeSelphIdComponent,
    AgreeSelphiComponent,
    FaceValidationTutorialComponent,
    DniValidationTutorialComponent,
    ValidationStatusComponent,
    CardSuccessProcessComponent,
    CardErrorProcessComponent,
    AppRoutingModule,
    CiecStatusComponent
  ]
})
export class ExternalModule { }
