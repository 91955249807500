import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteBuilder } from 'src/app/route-builder';
import { FacephiService } from '../../services/facephi.service';
import { ProcessStatus } from '../../models/process-status.model';
import { IdentityDocumentType } from '../../models/id-document-type.model';

@Component({
  selector: 'app-document-validation-process',
  templateUrl: './document-validation-process.component.html',
  styleUrls: ['./document-validation-process.component.scss']
})
export class DocumentValidationProcessComponent implements OnInit {

  public step: number = 1
  public processStatus: ProcessStatus;
  public processing: boolean = false;
  public routeBuilder: RouteBuilder;
  public documentType: IdentityDocumentType = 'DNI';

  constructor(private facephiService: FacephiService, private router: Router, public route: ActivatedRoute) {
    this.routeBuilder = new RouteBuilder(route);
  }

  ngOnInit(): void {
    const processId = this.routeBuilder.getValidationProcessId();
    const clientId = this.routeBuilder.getClientId()
    this.processing = true;
    this.facephiService.getStatus(processId, clientId).subscribe(
      {
        next: () => status => {
          this.processStatus = status;
          if (this.processStatus.document_validated) {
            this.router.navigate([this.routeBuilder.getStatusRoute()]);
          }
        },
        error: () => { },
        complete: () => this.processing = false
      })
  }

  public setStep(event) {
    this.step = event
  }
  
  public setDocumentType(type: IdentityDocumentType){
    this.documentType = type
  }
}
