<span class="container-logo">
  <svg class="logo" style="width: 50px;height: 50px;" id="Modo_de_aislamiento" data-name="Modo de aislamiento"
  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.45 55.83">
  <defs>
    <style>
      .cls-1 {
        fill: #ffffff;
      }
    </style>
  </defs>
  <circle class="cls-1" cx="25.23" cy="27.71" r="7.33" />
  <circle class="cls-1" cx="25.23" cy="48.5" r="7.33" />
  <circle class="cls-1" cx="25.23" cy="7.33" r="7.33" />
  <circle class="cls-1" cx="7.33" cy="38.09" r="7.33" />
  <circle class="cls-1" cx="43.12" cy="17.74" r="7.33" />
  <circle class="cls-1" cx="43.03" cy="38.49" r="7.33" />
  <circle class="cls-1" cx="7.42" cy="17.81" r="7.33" />
</svg>
</span>
