import { Directive, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Directive()
export class StepperManager {

  constructor() { }

  @Input()
  public step: number;

  @Output()
  public stepChange = new EventEmitter<number>();

  public setStep(step: number) {
    this.stepChange.emit(step)
  }

  public nextStep() {
    this.stepChange.emit(this.step + 1)
  }

  public previousStep() {
    if (this.step <= 1) return;
    this.stepChange.emit(this.step - 1)
  }
}
