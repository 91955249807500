<div class="container p-3">
  <div class="row h-100">

    <div class="box" style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    ">
      <div class="circle"></div>

      <span class="reveal-text" style="word-break: break-all; display: flex; flex-direction: column">
        <i [class]="errorInfo.icon" class="icon bi"></i>
        <p style="word-break: normal;">{{ errorInfo.message }}</p>
        <!-- <h2>{{ errorInfo.message }}</h2> -->
      </span>

      <span class="logoPosition">
        <logo-agree></logo-agree>
      </span>

      <button *ngIf="errorInfo?.redirectTo === 'document-authentication'" type="button" class="btn white-btn btn-block"
        [routerLink]="[routeBuilder.getDocumentValidationRoute()]" routerLinkActive="active">
        Reintentar
      </button>

      <button *ngIf="errorInfo?.redirectTo === 'face-authentication'" type="button" class="btn white-btn btn-block"
        [routerLink]="[routeBuilder.getFaceValidationRoute()]" routerLinkActive="active">
        Reintentar
      </button>

      <button *ngIf="errorInfo?.redirectTo === 'user-status'" type="button" class="btn white-btn btn-block"
        [routerLink]="[routeBuilder.getStatusRoute()]" routerLinkActive="active">
        Ir al inicio
      </button>

    </div>
  </div>
</div>
