import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ag-conditions',
  templateUrl: './conditions.component.html',
  styleUrls: ['./conditions.component.scss']
})
export class ConditionsComponent implements OnInit {

  @Input()
  public companyName: string;

  constructor() { }

  ngOnInit(): void {
  }

}
