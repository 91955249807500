import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouteBuilder } from 'src/app/route-builder';
import { IdentityDocumentType } from '../../models/id-document-type.model';
import { StepperManager } from '../../stepper-manager';

@Component({
  selector: 'dni-validation-tutorial',
  templateUrl: './dni-validation-tutorial.component.html',
  styleUrls: ['./dni-validation-tutorial.component.scss']
})
export class DniValidationTutorialComponent extends StepperManager implements OnInit {

  @Input()
  public processing: boolean;

  
  @Output() 
  public setDocumentType = new EventEmitter<IdentityDocumentType>();
  
  public routeBuilder: RouteBuilder;

  constructor(public route: ActivatedRoute) {
    super();
    this.routeBuilder = new RouteBuilder(route);
  }

  public selectDocumentType(type: IdentityDocumentType){
    this.setDocumentType.emit(type);
    this.nextStep();
  }

  ngOnInit(): void {
  }

}
 