
/**
*   Request Model to SaveDocument images for the validation process
*   /validation-process/:authorization_id/client/:user_id/document
**/


/**
*   authorization_id: hash uuid del proceso
*   user_id: hash uuid del cliente
**/

export class SaveDocumentRequest {
  constructor(data: Partial<SaveDocumentRequest> = {}) {
    Object.assign(this, data);
  }
  front_document: any;
  back_document: any;
}
