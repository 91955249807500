<ag-card>
    <ng-container cardBody>
        <spinner *ngIf="processing"></spinner>

        <ng-container *ngIf="!processing">
            <span class="display-flex">
                <img class="face-identity" src="../../../assets/agree/face-id.png" alt="face-identity" />
                <p class="text-description">
                    Vamos a validar tu identidad pidiéndote que te saques una selfie
                </p>
            </span>
        </ng-container>
    </ng-container>
    
    <ng-container cardFooter *ngIf="!processing">
        <span style="display: flex; justify-content: center">
            <button type="button" class="btn back-btn btn-block" [routerLink]="[this.routeBuilder.getStatusRoute()]"
                routerLinkActive="active">
                Volver
            </button>
            <button type="button" class="btn agree-btn btn-block" (click)="nextStep()">
                Validar Rostro
            </button>
        </span>
    </ng-container>
</ag-card>