<ng-container [ngSwitch]="step">
  <div class="container">
    <div class="row h-100">
      <dni-validation-tutorial *ngSwitchCase="1" [processing]="processing" (setDocumentType)="setDocumentType($event)"
        [(step)]="step"></dni-validation-tutorial>

      <agree-selph-id *ngSwitchCase="2" [processing]="processing" [documentType]="documentType" [(step)]="step">
      </agree-selph-id>

      <card-success-process *ngSwitchCase="3" [(step)]="step" [linkToContinue]="[
        this.routeBuilder.getFaceValidationRoute()
      ]" text="Validación de Documento Exitosa!"></card-success-process>

    </div>
  </div>
</ng-container>