import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteBuilder } from 'src/app/route-builder';
import { StepperManager } from '../../stepper-manager';

@Component({
  selector: 'face-validation-tutorial',
  templateUrl: './face-validation-tutorial.component.html',
  styleUrls: ['./face-validation-tutorial.component.scss']
})
export class FaceValidationTutorialComponent extends StepperManager implements OnInit {

  @Input()
  public processing: boolean;

  public routeBuilder: RouteBuilder;

  constructor(route: ActivatedRoute, public router: Router) {
    super();
    this.routeBuilder = new RouteBuilder(route);
  }

  ngOnInit(): void {
  }

}
