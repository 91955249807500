import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FPhi } from '@facephi/selphi-widget-web';
import { RouteBuilder } from 'src/app/route-builder';
import { FacephiService } from '../../services/facephi.service';
import { StepperManager } from '../../stepper-manager';

@Component({
  selector: 'agree-selphi',
  templateUrl: './agree-selphi.component.html',
  styleUrls: ['./agree-selphi.component.scss']
})
export class AgreeSelphiComponent extends StepperManager implements OnInit {
  @Input()
  public processing: boolean;

  public routeBuilder: RouteBuilder;

  constructor(private facephiService: FacephiService, private route: ActivatedRoute) {
    super();
    this.routeBuilder = new RouteBuilder(route);
  }

  ngOnInit(): void {
  }

  FPhiSelphiConstants = FPhi.Selphi;
  objectEntriesFunc = Object.entries;

  readonly FPhiCameraResolutions = {
    res480p: { title: '640x480', width: 640, height: 480 },
    res600p: { title: '800x600', width: 800, height: 600 },
    res768p: { title: '1024x768', width: 1024, height: 768 },
    res720p: { title: '1280x720 (720p)', width: 1280, height: 720 },
    res1080p: { title: '1920x1080 (1080p)', width: 1920, height: 1080 },
  };

  isWidgetCaptureStarted = true;

  widgetFaceTracking = false;
  widgetInteractible = false;
  widgetTutorial = false;
  widgetStabilizationStage = false;
  widgetVideoRecord = false;
  widgetShowLog = false;
  widgetDebugMode = false;

  widgetCameraResolution = 'res1080p';
  widgetCameraType: number = this.FPhiSelphiConstants.CameraType.Front;
  widgetLivenessMode: number = FPhi.Selphi.LivenessMode.Passive;

  get widgetCameraWidth(): number { return this.FPhiCameraResolutions[this.widgetCameraResolution].width; }
  get widgetCameraHeight(): number { return this.FPhiCameraResolutions[this.widgetCameraResolution].height; }


  onStartCapture() { this.isWidgetCaptureStarted = true; }
  onStopCapture() { this.isWidgetCaptureStarted = false; }

  // Widget event handlers
  public onModuleLoaded(eventData) {
  }

  public onStabilizing(stabilizingResult) {
  }

  public onExtractionFinish(extractionResult) {
    const templateRaw = extractionResult.detail.templateRaw;
    const bestImageTokenized = extractionResult.detail.bestImageTokenized;
    const croppedImage = extractionResult.detail.bestImageCropped.currentSrc;
    this.saveSelfie(templateRaw, bestImageTokenized, croppedImage);
  }

  public saveSelfie(templateRaw, bestImageTokenized, croppedImage) {
    this.processing = true;
    const authorization_id = this.routeBuilder.getValidationProcessId();
    const user_id = this.routeBuilder.getClientId();
    this.facephiService.saveSelfie(authorization_id, user_id, bestImageTokenized, templateRaw, croppedImage)
      .subscribe({
        next: () => this.setStep(3),
        error: () => {
          this.setStep(4)
        },
        complete: () => this.processing = false
      })
  }

  public onUserCancel() {
    this.isWidgetCaptureStarted = false;
  }

  public onExceptionCaptured(exceptionResult) {
    this.isWidgetCaptureStarted = false;
  }

  public onLivenessError(livenessErrorResult) {

    switch (livenessErrorResult.detail.livenessErrorType) {
      case this.FPhiSelphiConstants.LivenessDiagnostic.Unsuccess:
        console.log('[Selphi] Liveness error: Blink or movement not detected');
        break;
      case this.FPhiSelphiConstants.LivenessDiagnostic.UnsuccessLowPerformance:
        console.log('[Selphi] Liveness error: Low performance');
        break;
      case this.FPhiSelphiConstants.LivenessDiagnostic.UnsuccessGlasses:
        console.log('[Selphi] Liveness error: Glasses detected');
        break;
      case this.FPhiSelphiConstants.LivenessDiagnostic.UnsuccessLight:
        console.log('[Selphi] Liveness error: Bad lighting conditions');
        break;
      case this.FPhiSelphiConstants.LivenessDiagnostic.UnsuccessNoMovement:
        console.log('[Selphi] Liveness error: No movement');
        break;
      case this.FPhiSelphiConstants.LivenessDiagnostic.UnsuccessWrongDirection:
        console.log('[Selphi] Liveness error: Wrong direction');
        break;
      case this.FPhiSelphiConstants.LivenessDiagnostic.UnsuccessTooFar:
        console.log('[Selphi] Liveness error: Face too far');
        break;
    }
  }

  public onLivenessErrorButtonClick() {
    this.isWidgetCaptureStarted = false;
  }

  public onExtractionTimeout(extractionTimeoutResult) {
    this.setStep(1)

  }

  public onTimeoutErrorButtonClick() {
    this.isWidgetCaptureStarted = true;
  }

  public onTrackStatus(eventData) {
    const trackStatusCode = Object.entries(this.FPhiSelphiConstants.TrackStatus).find(e => e[1] === eventData.detail.code);
  }

}
