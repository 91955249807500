<div class="card h-100">
    <div class="card-body h-100 display-flex space-between">
        <ng-content select="[cardHeader]"></ng-content>

        <span class="centered" [class.w-100]="bodyFullWidth">
            <h5 *ngIf="title" class="card-title">{{ title }}</h5>
            <p *ngIf="text" class="card-text">{{ text }}</p>
            <ng-content select="[cardBody]"></ng-content>
        </span>

        <ng-content select="[cardFooter]"></ng-content>
    </div>
</div>
