import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'logo-agree',
  templateUrl: './logo-agree.component.html',
  styleUrls: ['./logo-agree.component.scss']
})
export class LogoAgreeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
