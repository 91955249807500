import { Component, Input, OnInit } from '@angular/core';
import { StepperManager } from '../../stepper-manager';

@Component({
  selector: 'card-error-process',
  templateUrl: './card-error-process.component.html',
  styleUrls: ['./card-error-process.component.scss']
})
export class CardErrorProcessComponent extends StepperManager implements OnInit {

  @Input()
  text: string;

  @Input()
  retryStep: number;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
