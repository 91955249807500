import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouteBuilder } from 'src/app/route-builder';
import { CiecService } from '../../services/ciec.service';

@Component({
  selector: 'ciec-status',
  templateUrl: './ciec-status.component.html',
  styleUrls: ['./ciec-status.component.scss']
})
export class CiecStatusComponent implements OnInit {

  constructor(
    private ciecService: CiecService,
    private route: ActivatedRoute
  ) {
    this.routeBuilder = new RouteBuilder(route);
  }

  public routeBuilder: RouteBuilder;
  public processing: boolean;

  public companyName: string;
  public fullName: string;
  public rfc: string;

  private requestUUID: string;
  public ciecPassword: string;
  public showPassword: boolean;

  public successMessage: string;
  public errorMessage: string;

  ngOnInit(): void {
    this.requestUUID = this.routeBuilder.getCiecRequestUUid();
    this.companyName = this.routeBuilder.getCompanyName();
    this.fullName = this.routeBuilder.getFullName();
    this.rfc = this.routeBuilder.getRfc();
  }

  public submit() {
    this.clearMessages();
    this.processing = true;
    this.ciecService.submit(this.requestUUID, this.ciecPassword, this.rfc).subscribe({
      next: (response) => {
        this.successMessage = '¡Clave aceptada, muchas gracias!'
        this.processing = false;
      },
      error: (error) => {
        this.errorMessage = '¡Hubo un error al guardar la clave!'
        this.processing = false;
      }
    });
  }

  public clearMessages() {
    delete this.errorMessage;
    delete this.successMessage;
  }
}
