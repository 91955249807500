import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CiecService {

  private apiBase = environment.api.ciec_base;
  private sendCiecPassword = this.apiBase + '/ciec/register';

  constructor(
    private http: HttpClient
  ) { }

  public submit(request_uuid: string, password: string, rfc: string): Observable<any> {
    const url = this.sendCiecPassword;

    const stream = this.http.post(url, {
      uuid: request_uuid,
      password: password,
      rfc: rfc
    });

    return stream;
  }
}

class CiecRequest {
  uuid: string;
  id: number;

  created_at: Date;
  updated_at: Date;

  status: {
    /**
      * | ID | Description |
      * |---:|-------------|
      * |  1 | COMPLETED   |
      * |  2 | PENDING     |
      */
    id: number;
    name: string;
  }

  validity?: {
    /**
      * | ID | Description |
      * |---:|-------------|
      * |  1 | VALID       |
      * |  2 | PENDING     |
      * |  3 | INVALID     |
      * |  4 | ERROR       |
      */
    id: number;
    name: string;
  }

  user: CiecUser;

  // constructor(data: Partial<CiecRequest> = {}) {
  //   Object.assign(this, data);
  // }
}

class CiecUser {
  created_at: Date;

  id: number;
  email: string;

  name: string;
  last_name: string;
  phone?: string;

  fiscal_id: any;

  company: {
    id: number;
    name: string;
    fiscal_id: any;
  };

  // constructor(data: Partial<CiecUser> = {}) {
  //   Object.assign(this, data);
  // }
}

