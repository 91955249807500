import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';
import { RouteBuilder } from './route-builder';


@Injectable()
export class InterceptorService implements HttpInterceptor {

  public authorization_id: string;
  public user_id: string;

  public routeBuilder: RouteBuilder;

  constructor(private router: Router, public route: ActivatedRoute) {
    this.routeBuilder = new RouteBuilder(route);
    this.route.root.children[0].params.subscribe((params) => {
      this.authorization_id = params["authorization_id"];
      this.user_id = params["user_id"];
    });

    console.log(route.snapshot['_routerState'].url);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isCiecService = req.urlWithParams.includes('ciec');
    if (isCiecService) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      retryWhen((error) => error.pipe(
        mergeMap((error) => {

          if (error.status == 404 || error.status == 400 || error.status === 500) {
            const agreeErrorCode = error.error.agree_code;
            if (agreeErrorCode) {
              const errorRoute = this.routeBuilder.createErrorRoute(this.authorization_id, this.user_id, agreeErrorCode);
              this.router.navigateByUrl(errorRoute);
            }
          }
          throw error;

        })
      )
      )
    )
  }
}
