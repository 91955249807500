import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouteBuilder } from 'src/app/route-builder';
import { FacephiService } from '../../services/facephi.service';

import { ErrorResponse, ProcessStatus } from '../../models/process-status.model';

@Component({
  selector: 'validation-status',
  templateUrl: './validation-status.component.html',
  styleUrls: ['./validation-status.component.scss']
})
export class ValidationStatusComponent implements OnInit {

  constructor(private facephiService: FacephiService, private route: ActivatedRoute) {
    this.routeBuilder = new RouteBuilder(route);
  }

  public routeBuilder: RouteBuilder;
  public processStatus: ProcessStatus;
  public processing: boolean = false;
  public error: ErrorResponse;

  ngOnInit(): void {
    this.processing = true;
    const processId = this.routeBuilder.getValidationProcessId();
    const clientId = this.routeBuilder.getClientId()

    this.processing = true;
    this.facephiService.getStatus(processId, clientId).subscribe(
      {
        next: (status) => {
          this.processStatus = status;
          this.processing = false;
        },
        error: (error) => {
          this.error = new ErrorResponse(error.error);
          this.processing = false
        },
        complete: () => this.processing = false,

      }
    )
  }

}
