import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProcessStatus } from '../models/process-status.model';
import { map, Observable, of } from 'rxjs';
import { JSend } from '../../jsend.model';
import { plainToClass } from 'class-transformer';
import { SaveSelfieRequest } from '../models/request/save-selfie-request.model';
import { SaveDocumentRequest } from '../models/request/save-document-request.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FacephiService {

  private apiBase = environment.api.base; // Api
  private processValidation = '/:authorization_id/:user_id'
  private status = this.apiBase + '/user-status' + this.processValidation
  private selfie = this.apiBase + '/face-authentication' + this.processValidation
  private document = this.apiBase + '/document-authentication' + this.processValidation

  constructor(
    private http: HttpClient,
  ) { }

  public getStatus(authorization_id: string, user_id: string): Observable<ProcessStatus> {
    let url = this.status
      .replace(':authorization_id', authorization_id)
      .replace(':user_id', user_id);

    let stream = this.http.get<JSend<{ user: ProcessStatus }>>(url);
    return stream.pipe(map(response => {
      return plainToClass(ProcessStatus, response.data.user);
    }));

  }

  public saveSelfie(authorization_id: string, user_id: string, image_tokenized?: string, template_raw?: string, best_image_cropped?: string) {
    let url = this.selfie
      .replace(':authorization_id', authorization_id)
      .replace(':user_id', user_id);

    const body = new SaveSelfieRequest({ image_tokenized, template_raw, best_image_cropped })
    let stream = this.http.post(url, body);
    return stream;

  }

  public saveDocument(authorization_id: string, user_id: string, front_document: string, back_document: string) {
    let url = this.document
      .replace(':authorization_id', authorization_id)
      .replace(':user_id', user_id);

    const body = new SaveDocumentRequest({ front_document: front_document, back_document: back_document })
    let stream = this.http.post(url, body);
    return stream;

  }
}
