
/**
*   Request Model to SaveSelfie image for the validation process
*   /:authorization_id/client/:user_id/selfie
**/

/**
*   authorization_id: hash uuid del proceso
*   user_id: hash uuid del cliente
**/

export class SaveSelfieRequest {
  constructor(data: Partial<SaveSelfieRequest> = {}) {
    Object.assign(this, data);
  }

  image_tokenized?: any;
  template_raw?: any;
  best_image_cropped?: any;
}
