<div class="container">
  <div class="row h-100">
    <ag-card>
      <h3 cardHeader *ngIf="processStatus && !processStatus.is_pending_manual" class="ag-title">
        Bienvenido {{ processStatus.name }} al proceso de validación de
        identidad!
      </h3>
      <h3 cardHeader *ngIf="processStatus?.is_pending_manual" class="ag-title">
        {{ processStatus.name }} su validación de identidad está siendo
        procesada..
      </h3>

      <ng-container cardBody>
        <span class="display-flex" style="text-align: center">
          <spinner *ngIf="processing"></spinner>

          <error-response-info [error]="error"></error-response-info>

          <ng-container *ngIf="!processing && !error">
            <ag-conditions [companyName]="processStatus.name + ' ' + processStatus.surname"></ag-conditions>

            <div class="h-100">
              <ng-container *ngIf="processStatus &&
              !processStatus.is_pending_manual">
                <div *ngIf="processStatus.validated.document" class="process-status">
                  <span class="process-name">¡Documento cargado con éxito!</span>
                </div>
                <div *ngIf="!processStatus.validated.document" class="process-status">
                  <span class="process-name">Validacion de documento</span>
                </div>
                <button *ngIf="!processStatus.document_validated" class="btn btn-block agree-btn"
                  [routerLink]="[routeBuilder.getDocumentValidationRoute()]">
                  Validar Documentación
                  <i class="bi bi-credit-card-2-front"></i>
                </button>
                <span class="alert alert-success" *ngIf="processStatus.document_validated">
                  Completada
                  <i class="status-icon bi bi-check-circle-fill"></i>
                </span>

                <div class="process-status">
                  <span class="process-name">Validacion de Rostro</span>
                </div>

                <button *ngIf="!processStatus.selfie_validated" class="btn btn-block agree-btn"
                  [routerLink]="[routeBuilder.getFaceValidationRoute()]" routerLinkActive="active">
                  Validar Rostro
                  <i class="bi bi-person-bounding-box"></i>
                </button>
                <!-- <div
                                    class="process-status alert alert-warning"
                                    *ngIf="!processStatus.document_validated"
                                >
                                    ¡Primero valide la documentación!
                                </div> -->

                <span class="alert alert-success" *ngIf="processStatus.selfie_validated">Completada
                  <i class="status-icon bi bi-check-circle-fill"></i>
                </span>
              </ng-container>
            </div>

            <div class="process-status alert alert-info" *ngIf="processStatus.message &&
            !processStatus.is_pending_manual">
              <div [innerHtml]="processStatus.message"></div>
            </div>
          </ng-container>

          <ng-container *ngIf="processStatus?.is_pending_manual">
            <div class="process-status alert alert-info">
              <h1>🧑‍🦱🪪</h1>
              <h2>
                ¡Su validación de identidad está siendo
                procesada!
              </h2>
              (Será notificado por email cuando este proceso
              finalice..)
            </div>
          </ng-container>
        </span>
      </ng-container>
    </ag-card>
  </div>
</div>