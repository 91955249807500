<ng-container [ngSwitch]="step">
  <div class="container p-3">
    <div class="row h-100">
      <face-validation-tutorial *ngSwitchCase="1" [processing]="processing" [(step)]="step"></face-validation-tutorial>

      <agree-selphi *ngSwitchCase="2" [processing]="processing" [(step)]="step">
      </agree-selphi>

      <card-success-process *ngSwitchCase="3" [(step)]="step" [linkToContinue]="[routeBuilder.getStatusRoute()]"
        text="Validación Exitosa!"></card-success-process>

    </div>
  </div>
</ng-container>
