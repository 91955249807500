import { ActivatedRoute } from "@angular/router";

export class RouteBuilder {
  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe((params) => {
      this.authorization_id = params["authorization_id"];
      this.user_id = params["user_id"];
      this.error_code = params["error_code"];
      this.ciec_request_id = params["ciec_request_id"];
      this.name = params["name"];
      this.surname = params["surname"];
      this.companyName = params["companyName"];
      this.rfc = params["rfc"];
    });
  }

  private authorization_id: string;
  private user_id: string;
  private error_code: string;
  private ciec_request_id: string;
  private name: string;
  private surname: string;
  private companyName: string;
  private rfc: string;

  public getFaceValidationRoute(): string {
    const route = "/face-authentication/" + this.authorization_id + "/" + this.user_id;
    return route;
  }

  public getDocumentValidationRoute(): string {
    const route = "/document-authentication/" + this.authorization_id + "/" + this.user_id;
    return route;
  }

  public getStatusRoute(): string {
    const route = "/user-status/" + this.authorization_id + "/" + this.user_id;
    return route;
  }

  public getErrorRoute(): string {
    const route = "/error/" + this.authorization_id + "/" + this.user_id + "/" + this.error_code;
    return route;
  }

  public createErrorRoute(authorization_id: string, user_id: string, agree_code: string,): string {
    const route = "/error/" + authorization_id + "/" + user_id + "/" + agree_code;
    return route;
  }

  public getValidationProcessId(): string {
    return this.authorization_id;
  }

  public getClientId(): string {
    return this.user_id;
  }

  public getErrorCode(): string {
    return this.error_code;
  }

  public getCiecRequestUUid() {
    return this.ciec_request_id;
  }
  public getName() {
    return this.name;
  }
  public getSurname() {
    return this.surname;
  }

  public getFullName() {
    return `${this.name} ${this.surname}`
  }

  public getCompanyName() {
    return this.companyName;
  }

  public getRfc() {
    return this.rfc;
  }
}
