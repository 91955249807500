<div class="card text-start">
  <div class="card-body small bg-light">
    <ol type="I">
      <li>
        <b>Consentimiento para el tratamiento de datos personales</b>
        <p>
          “Declaro y manifiesto que
          <b>Agree Mex, S.A. de C.V.</b>, puso a mi disposición su
          <a href="https://app.agree.ag/documents/privacy/mx" target="_blank">aviso de privacidad</a>
          y que he leído, conozco y entiendo dicho aviso.
        </p>
        <p>
          Otorgo de forma expresa mi consentimiento para que mis datos
          personales sean tratados conforme a lo señalado en el referido
          <a href="https://app.agree.ag/documents/privacy/mx" target="_blank">aviso de privacidad</a>
          y las finalidades determinadas previstas en dicho aviso.”
        </p>
      </li>
      <li>
        <b>Aceptación de Términos y Condiciones</b>
        <p>
          “<b>{{ companyName }}</b> declara y manifiesta que ha leído,
          conoce y entiende los
          <a href="https://app.agree.ag/documents/terms/mx" target="_blank">Términos y Condiciones</a>, y
          manifiesta expresamente su voluntad de sujetarse y
          obligarse conforme a los mismos.”
        </p>
      </li>

      <li>
        <b>Autorización Información Crediticia</b>
        <p>
          “<b>{{ companyName }}</b> declaro conocer, convengo y autorizo
          que mi información y documentación que he entregado, entregaré o
          compartiré con <b>Agree Mex, S.A. de C.V.</b>, o que
          <b>Agree Mex, S.A. de C.V.</b>, recabe, obtenga, centralice o
          gestione, incluyendo información crediticia, legal, fiscal y
          financiera, serán utilizadas para:
        </p>
        <ol>
          <li>
            Reunir, obtener, centralizar y gestionar mi información y
            documentación necesarias para la solicitud y tramitación de
            préstamos, créditos y otros tipos de financiamiento;
          </li>
          <li>
            Solicitar y tramitar préstamos, créditos y otros tipos de
            financiamiento ante entidades financieras y comerciales;
          </li>
          <li>
            Cumplir las obligaciones derivadas de la relación jurídica
            relativa a la solicitud, prestación, puesta a disposición o
            contratación de los servicios de
            <b>Agree Mex, S.A. de C.V.</b>;
          </li>
          <li>
            Analizar la viabilidad de pago de préstamos, créditos y
            otros tipos de financiamiento que se me pudieran otorgar,
            así como mi solvencia crediticia y capacidad financiera y de
            pago;
          </li>
          <li>
            En su caso, celebrar y firmar con entidades financieras y
            comerciales contratos, convenios y demás acuerdos y
            documentos relativos a préstamos, créditos y otros tipos de
            financiamiento a ser otorgados por dichas entidades; y
          </li>
          <li>
            Los demás fines previstos en los
            <a href="https://app.agree.ag/documents/terms/mx" target="_blank">Términos y Condiciones</a>
            y el
            <a href="https://app.agree.ag/documents/privacy/mx" target="_blank">aviso de privacidad</a>.
          </li>
        </ol>
        <br>
        <span>
          Autorizo expresamente a
          <b>Agree Mex, S.A. de C.V.</b>, para que lleve a cabo
          Investigaciones sobre mi comportamiento Crediticio en las
          Sociedades de Información Crediticia (SIC) que estime
          conveniente. Conozco la naturaleza y alcance de la información
          que se solicitará, del uso que se le dará y que se podrán
          realizar consultas periódicas de mi historial crediticio.
          Consiento que esta autorización tenga una vigencia de 3 años
          contados a partir de hoy, y en su caso mientras mantengamos
          relación jurídica. Acepto que este documento quede bajo
          propiedad de <b>Agree Mex, S.A. de C.V.</b>, y/o la SIC
          consultada para efectos de control y cumplimiento del artículo
          28 de la Ley para Regular las Sociedades de Información
          Crediticia.”
        </span>
      </li>
    </ol>
  </div>
</div>