import { Component, OnInit, Input } from '@angular/core';
import { ErrorResponse } from 'src/app/external/models/process-status.model';

@Component({
  selector: 'error-response-info',
  templateUrl: './error-response-info.component.html',
  styleUrls: ['./error-response-info.component.scss']
})
export class ErrorResponseInfoComponent implements OnInit {

  @Input()
  public error: ErrorResponse

  constructor() { }

  ngOnInit(): void {
  }

}
