import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card/card.component';
import { LogoAgreeComponent } from './logo-agree/logo-agree.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ErrorResponseInfoComponent } from './error-response-info/error-response-info.component';
import { ConditionsComponent } from './conditions/conditions.component';

@NgModule({
  declarations: [
    CardComponent,
    LogoAgreeComponent,
    SpinnerComponent,
    ErrorResponseInfoComponent,
    ConditionsComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CardComponent,
    SpinnerComponent,
    LogoAgreeComponent,
    ErrorResponseInfoComponent,
    ConditionsComponent
  ]
})
export class UiModule { }
