<div *ngIf="error">

  <span class="display-flex">
    <i [class]="error.icon" class="icon bi"></i>

    <p class="text-description">
      {{ error.message_translated }}
    </p>
  </span>
</div>
