import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FPhi } from '@facephi/selphid-widget-web';
import { combineLatest, Observable, of, repeat, Subscription, timer } from 'rxjs';
import { RouteBuilder } from 'src/app/route-builder';
import { environment } from 'src/environments/environment';
import { IdentityDocumentCaptureMode, IdentityDocumentType } from '../../models/id-document-type.model';
import { FacephiService } from '../../services/facephi.service';
import { S3Service } from '../../services/s3.service';
import { StepperManager } from '../../stepper-manager';

@Component({
  selector: 'agree-selph-id',
  templateUrl: './agree-selph-id.component.html',
  styleUrls: ['./agree-selph-id.component.scss']
})
export class AgreeSelphIdComponent extends StepperManager implements OnInit {

  public modeSelected: "manual" | "capture";

  @Input()
  public documentType: IdentityDocumentType = 'DNI';

  @Input()
  public processing: boolean;

  public routeBuilder: RouteBuilder;
  public settings: SelphiIdSettings = new SelphiIdSettings();
  private subscriptions: Subscription[] = [];

  constructor(private facephiService: FacephiService, private s3Service: S3Service, route: ActivatedRoute, private router: Router) {
    super();

    this.routeBuilder = new RouteBuilder(route);
  }

  ngOnInit(): void {
    this.modeSelected = "capture"
    this.settings.widgetCaptureMode = IdentityDocumentCaptureMode[this.documentType];
  }

  startManualMode() {
    this.modeSelected = "manual";
    this.settings.widgetStartSimpleMode = true;
    this.settings.isWidgetCaptureStarted = false;
  }

  startCaptureMode() {
    this.modeSelected = "capture";
    this.settings.widgetStartSimpleMode = false;
    this.settings.isWidgetCaptureStarted = true;
  }


  onStartCapture() {
    console.log("onStartCapture")

    if (this.settings.widgetForceLandscape) {
      this.settings.widgetContainerHeight = '56.25%';
      this.settings.widgetContainerWidth = '100%';
    } else {
      this.settings.widgetContainerWidth = '100%';
      this.settings.widgetContainerHeight = '100%';
    }

    if (this.settings.widgetLicenseKey === "") {
      this.settings.widgetLicenseKey = window.prompt("Please, enter the license key before start the operations: ", "")
    }

    this.settings.widgetStartSimpleMode = false;
    this.settings.isWidgetCaptureStarted = true;

  }

  onStopCapture() {
    console.log("onStopCapture")
    this.settings.widgetStartSimpleMode = false;
    this.settings.isWidgetCaptureStarted = false;
  }

  onModuleLoaded(eventData) {
    console.log("onModuleLoaded", eventData)
  }

  onExtractionFinished(extractionResult) {
    console.log('Image 1 --------------------------------------------')
    console.log(extractionResult.detail.images[0])
    console.log('Image 2 --------------------------------------------')
    console.log(extractionResult.detail.images[1])
    console.log('----------------------------------------------------')
    console.log(extractionResult)
    
    let frontImage = this.extractImageSource(extractionResult.detail.images[0].src);
    let backImage = undefined;

    if (this.documentType === 'DNI') {
      backImage = this.extractImageSource(extractionResult.detail.images[1].src);
    }

    this.saveDocument(frontImage, backImage);

    this.settings.widgetStartSimpleMode = false;
    this.settings.isWidgetCaptureStarted = false;
  }

  public saveDocument(frontImage, backImage) {
    const processId = this.routeBuilder.getValidationProcessId();
    const clientId = this.routeBuilder.getClientId();

    this.processing = true;

    this.subscriptions.push(this.s3Service.getUploadUrl(processId, clientId, this.documentType)
      .subscribe(({ front_url, back_url }) => {
        const frontUploadStream = front_url ? this.s3Service.upload(front_url, frontImage) : of([]);
        const backUploadStream = back_url ? this.s3Service.upload(back_url, backImage) : of([]);

        this.subscriptions.push(combineLatest([frontUploadStream, backUploadStream]).subscribe(() => {
          const checkStatus = { count: 6, delay: 1500 };

          this.subscriptions.push(this.facephiService.getStatus(processId, clientId)
            .pipe(repeat({
              count: checkStatus.count,
              delay: (count) => timer(count * checkStatus.delay)
            }))
            .subscribe(status => {
              checkStatus.count--;
              // If the document has no tag in document status, it means that the file upload was successful.
              if (!('tag' in status.document_status)) {
                if (status.validated.document) {
                  this.setStep(3);
                  this.processing = false;
                } else {
                  this.router.navigate([this.routeBuilder.createErrorRoute(processId, clientId, 'INVALID_DOCUMENT')]);
                }
              } else if (checkStatus.count === 0) {
                this.router.navigate([this.routeBuilder.createErrorRoute(processId, clientId, 'DOCUMENT_STATUS_TIMEOUT')]);
              }
            }));
        }))
      }))
  }

  private extractImageSource(src) {
    const onlySourceImage = src.split(',')[1];
    return onlySourceImage;
  }

  onUserCancelled() {
    console.log("onUserCancelled")

    this.settings.widgetStartSimpleMode = false;
    this.settings.isWidgetCaptureStarted = false;
    this.setStep(1);
  }

  onExceptionCaptured(exceptionResult) {
    console.log("onExceptionCaptured", exceptionResult)

    this.settings.widgetStartSimpleMode = false;
    this.settings.isWidgetCaptureStarted = false;
  }

  onExtractionTimeout(eventInfo) {
    console.log("onExtractionTimeout", eventInfo)

    this.settings.widgetStartSimpleMode = false;
    this.settings.isWidgetCaptureStarted = false;
  }

  public onTrackStatus(eventData) {
    const trackStatusCode = Object.entries(FPhi.SelphID.TrackStatus).find(e => e[1] === eventData.detail.code);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

export class SelphiIdSettings {
  constructor(data: Partial<SelphiIdSettings> = {}) {
    Object.assign(this, data);
  }

  public fPhiSelphIdConstants = FPhi.SelphID;
  objectEntriesFunc = Object.entries;

  readonly FPhiCameraResolutions = {
    res480p: { title: '640x480', width: 640, height: 480 },
    res600p: { title: '800x600', width: 800, height: 600 },
    res768p: { title: '1024x768', width: 1024, height: 768 },
    res720p: { title: '1280x720 (720p)', width: 1280, height: 720 },
    res1080p: { title: '1920x1080 (1080p)', width: 1920, height: 1080 },
  };

  public widgetLicenseKey = environment.facephID_license_key;
  public isWidgetCaptureStarted = true;
  public widgetCaptureMode: number = FPhi.SelphID.Mode.Full;
  public widgetCameraResolution = 'res1080p';
  // public widgetCaptureSensibility: number = FPhi.SelphID.CaptureSensibility.Auto;
  public widgetPreviewCapture = true;
  public widgetForceLandscape = false;
  public widgetInitialTip = false;
  public widgetDebugMode = false;
  public widgetVideoRecord = false;
  public widgetShowLog = false;
  public widgetStartSimpleMode = false;
  public widgetContainerWidth = '100%';
  public widgetContainerHeight = '100%';

  get widgetCameraWidth(): number {
    return this.FPhiCameraResolutions[this.widgetCameraResolution].width;
  }

  get widgetCameraHeight(): number {
    return this.FPhiCameraResolutions[this.widgetCameraResolution].height;
  }
}
