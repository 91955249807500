<ag-card>
    <ng-container cardBody style="justify-content: center">
        <spinner *ngIf="processing"></spinner>
        <ng-container *ngIf="!processing">
            <span class="display-flex">
                <img
                    class="dni-identity"
                    src="../../../assets/agree/dni-identity.png"
                    alt="dni-identity"
                />

                <p class="text-description">
                    Ahora vamos a validar sus documentos.
                </p>
                <ul>
                    <li>Busque un lugar con buena iluminación.</li>
                    <li>Verifique que las imagenes salgan nítidas.</li>
                </ul>
            </span>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!processing" cardFooter>
        <span style="display: flex; justify-content: center">
            <button
                type="button"
                class="btn back-btn btn-block"
                [routerLink]="[routeBuilder.getStatusRoute()]"
                routerLinkActive="active"
            >
                Volver
            </button>
            <button
                type="button"
                class="btn agree-btn btn-block"
                (click)="selectDocumentType('DNI')"
            >
                Utilizar Cedula de Identidad
            </button>
            <!-- <button type="button" class="btn agree-btn btn-block" (click)="selectDocumentType('PASSPORT')">
        Utilizar Pasaporte
      </button> -->
        </span>
    </ng-container>
</ag-card>
