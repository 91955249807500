import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentValidationProcessComponent } from './external/components/document-validation-process/document-validation-process.component';
import { FaceValidationProcessComponent } from './external/components/face-validation-process/face-validation-process.component';
import { ValidationStatusComponent } from './external/components/validation-status/validation-status.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PageErrorComponent } from './components/page-error/page-error.component';
import { CiecStatusComponent } from './external/components/ciec-status/ciec-status.component';

const routes: Routes = [

  { path: 'user-status/:authorization_id/:user_id', component: ValidationStatusComponent },
  { path: 'document-authentication/:authorization_id/:user_id', component: DocumentValidationProcessComponent },
  { path: 'face-authentication/:authorization_id/:user_id', component: FaceValidationProcessComponent },
  { path: 'ciec-request/:ciec_request_id/:name/:surname/:companyName/:rfc', component: CiecStatusComponent },
  { path: 'epyme', loadChildren: () => import('./external/modules/epyme/epyme.module').then(m => m.EpymeModule) },
  { path: 'nip', loadChildren: () => import('./external/modules/nip/nip.module').then(m => m.NipModule) },
  { path: 'error/:authorization_id/:user_id/:error_code', component: PageErrorComponent },
  { path: '**', redirectTo: 'not-found' },
  { path: 'not-found', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  exports: [RouterModule]
})
export class AppRoutingModule { }
